import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';
import { Carte2 } from 'server/src/app/carte/types';

import Layout from 'molecules/Layout';
import WineCarte from 'organisms/WineCarte';

import 'styles/pages/weinkarte.scss';

export const WINE_CARTE_QUERY = graphql`
  query WineCarteQuery {
    logo: file(relativePath: { eq: "logos/thamarai-weinkarte-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carte(id: { eq: "weinkarte" }) {
      id
      buckets {
        id
        title
        position
        offers {
          id
          product {
            ... on Beverage {
              id
              title
              subtitle
              maker
              description
              tags
              year
              origin
            }
          }
          active
          position
          quotes {
            id
            price
            position
          }
          variants {
            checked
            delta
            iconName
            id
            position
            title
          }
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
  carte: Carte2;
}

export interface Props {}

const WineCartePage: React.FC<Props> = () => {
  const { logo, carte } = useStaticQuery<Data>(WINE_CARTE_QUERY);

  return (
    <Layout
      className={`WineCartePage`}
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/weine-aus-heilbronn-und-indien"
          canonical="/weine-aus-heilbronn-und-indien"
          lang="de-DE"
          title="Weinkarte"
          description="Im Weinkeller des THAMARAI lagern wir ausgewählte Qualitätsweine aus dem Heilbronner Weinbaugebiet sowie indische Weine der Sula Vineyards."
        />
      )}
      header={() => (
        <Layout.Header
          title="Ausgewählte Weine aus dem Heilbronner Weinanbaugebiet"
          subtitle="Exklusive indische Weine von Sulas Vineyards"
          abstract="Das Heilbronner Unterland, berühmt für seine Rotweine, ist das Herz des Württemberger Weinbaugebiets. Insgesamt gibt es 120 Familienbetriebe in Heilbronn die sich auf die Herstellung von Qualitätsweinen spezialisiert haben. Die Bekanntheit des Riesling und des Trollingers gehen weit über die Landesgrenzen hinaus. Das THAMARAI Restaurant ansässig inmitten dieser traditionsreichen Weinstadt profitiert von der großen Auswahl an Qualitäts- und Prädikatsweinen. Das THAMARAI hat einen eigenen Gewölbekeller ausschließlich für die Lagerung von Weinen. Wir bauen unser Sortiment Stück für Stück aus, wobei der Fokus auf Weinen aus der Region liegt. Zu unseren Lieblingen wie dem Trollinger mit Lemberger oder dem Stiftsberger Riesling gesellen sich eine Reihe indischer Weine um unser Weinsortiment abzurunden. Dabei sind die Rot- und Weißweine der Sula Vineyards aus Mumbai in Indien von besonderer Bedeutung. Sie bieten eine interessante geschmackliche Note - ideal beim Verzehr von würzigen indisch-tamilischen Speisen."
          image={logo.childImageSharp}
          alt="THAMARAI Weinkarte Exklusives Logo"
        />
      )}
    >
      <WineCarte carte={carte} />
    </Layout>
  );
};

export default WineCartePage;
