import React from 'react';
import Icon, { IconTheme } from '@soosap/sushi/Icon';
import Chip, { ChipSize, ChipTheme } from '@soosap/sushi/Chip';
import { Offer2, Beverage } from 'server/src/app/carte/types';
import GrapesIcon from 'icons/Grapes';

export interface Props {
  offer: Offer2;
  bucketIndex: number;
  bucketTotal: number;
}

const MenuItem: React.FC<Props> = ({ offer, bucketIndex, bucketTotal }) => {
  const product = offer.product as Beverage;

  return (
    <li className={`WineCarte__MenuItem MenuItem`}>
      <h5 className="MenuItem__heading">
        <span className="MenuItem__subtitle">{product.subtitle}</span>
        <span className="MenuItem__title">{product.title}</span>
      </h5>
      <div className="MenuItem__maker" title={product.origin}>
        {product.maker}
      </div>
      {product.tags && (
        <div className="MenuItem__tags">
          {product.tags.map(tag => (
            <Chip
              key={tag}
              outlined
              theme={ChipTheme.DARK}
              size={ChipSize.MINI}
            >
              {tag}
            </Chip>
          ))}
          {product.year && (
            <Chip
              key="year"
              outlined
              theme={ChipTheme.DARK}
              size={ChipSize.MINI}
            >
              {product.year}
            </Chip>
          )}
        </div>
      )}
      <div className="MenuItem__description">
        {product.description}&nbsp;
        <span className="MenuItem__price">
          {(offer.quotes[0].price / 100).toFixed(2)}
        </span>
      </div>
      {bucketIndex + 1 < bucketTotal && (
        <div className="MenuItem__divider">
          <Icon
            className={`MenuItem__grapes MenuItem__grapes--${bucketIndex % 3}`}
            svg={GrapesIcon}
            theme={IconTheme.SECONDARY}
          />
        </div>
      )}
    </li>
  );
};

export default MenuItem;
